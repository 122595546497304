/* stylelint-disable selector-class-pattern */

.videoLoop {
  position: relative;
  width: 100%;
}

.videoLoop__fallbackImage {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  vertical-align: top;

  *[autoplay-blocked] & {
    display: block;
  }
}

.videoLoop video,
.videoLoop img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  vertical-align: top;
}

.videoLoop video {
  background-image: var(--poster);
  background-size: cover;
}

.wrapper {
  position: relative;
  display: block;
  margin: 0;
}

.videoLoop--played img {
  display: none;
}

.wrapper figure {
  margin: 0;
}
